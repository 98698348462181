<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("absence.title") }}</h1>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <div class="section-action">
          <div class="btn-new-create-action">
            <v-btn
              v-if="listAbsence.length <= 1"
              class="btn-create"
              @click="onCreate()"
            >
              {{ $t("absence.create") }}
            </v-btn>
          </div>
        </div>
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listAbsence.length > 0">
              <thead>
                <tr>
                  <th class="text-start">
                    {{ $t("absence.no") }}
                  </th>
                  <th class="text-left">
                    {{ $t("absence.name") }}
                  </th>
                  <th class="text-left">{{ $t("absence.type") }}</th>
                  <th class="text-left">
                    {{ $t("absence.value") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in listAbsence" :key="idx">
                  <td>
                    {{ pagination.current_page * 10 - 10 + idx + 1 }}
                  </td>
                  <td>
                    {{ item.per_time }}
                  </td>
                  <td>
                    {{ $t("absence." + item.type) }}
                  </td>
                  <td>
                    {{ $helpers.numberFormatter(item.value) }}
                  </td>
                  <td class="text-end">
                    <v-menu transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined v-bind="attrs" v-on="on">
                          <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            class="btn-edit"
                            @click="onEdit(item.id)"
                            >{{$t("education.edit")}}</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title
                            class="btn-delete"
                            @click="onDeleted(item.id)"
                            >{{$t("education.delete")}}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />

            <Pagination
              v-if="pagination.total_pages > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="fetchAbsence"
            >
            </Pagination>

            <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
              <template v-slot="{ close }">
                <EditAbsence
                  :absence="absence"
                  @close="close"
                  @success="fetchAbsence"
                />
              </template>
            </ModalEdit>

            <ModalDelete>
              <template v-slot="{ close }">
                <DeleteAbsence
                  :absenceId="absenceId"
                  @close="close"
                  @success="fetchAbsence"
                />
              </template>
            </ModalDelete>

            <Loading v-if="isLoading" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import EditAbsence from "@/components/Absence/EditAbsence";
import DeleteAbsence from "@/components/Absence/DeleteAbsence";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditAbsence,
    DeleteAbsence,
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      searchItem: "",

      listAbsence: [],
      absence: {},
      absenceId: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchAbsence();
    },
    onCreate() {
      this.$router.push({ name: "absence.create" }).catch(() => {});
    },

    filterAbsence(absenceId) {
      return (
        this.listAbsence.filter((item) => {
          return item.id == absenceId;
        })[0] || {}
      );
    },

    onEdit(absenceId) {
      this.absence = {
        ...this.filterAbsence(absenceId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(absenceId) {
      this.absenceId = absenceId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchAbsence() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/payroll/absences`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listAbsence = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listAbsence.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch(() => {});
    },
  },
  created() {
    this.fetchAbsence();
  },
};
</script>

<style scoped lang="scss">
</style>
